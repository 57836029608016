<template>
    <div class="page bg-white">
        <el-form size="small" :inline="true" class="query-form" ref="searchForm" :model="searchForm">
            <el-form-item prop="cityId">
                <el-cascader v-model="searchForm.cityId" placeholder="城市"
                             :options="cityOpt" :props="{ value: 'id', label: 'name' }"
                             @change="changeCity" style="width: 170px"></el-cascader>
            </el-form-item>
            <el-form-item prop="hospitalId">
                <el-select v-model="searchForm.hospitalId" placeholder="医院" @change="changeHospital" style="width: 160px">
                    <el-option
                            v-for="item in hospitalOpt"
                            :key="item.id"
                            :label="item.hospitalName"
                            :value="item.id">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item prop="departmentId">
                <el-select v-model="searchForm.departmentId" placeholder="科室" style="width: 140px">
                    <el-option
                            v-for="item in departmentOpt"
                            :key="item.id"
                            :label="item.departmentName"
                            :value="item.id">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item prop="patientName">
                <el-input v-model="searchForm.patientName" placeholder="患者姓名" maxlength="50" clearable style="width: 140px"></el-input>
            </el-form-item>
            <el-form-item prop="patientPhone">
                <el-input v-model="searchForm.patientPhone" placeholder="患者手机号" maxlength="11" clearable style="width: 140px"></el-input>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" @click="refreshList(1)" size="small" icon="el-icon-search">查询</el-button>
                <el-button @click="resetSearch()" size="small" icon="el-icon-refresh-right">重置</el-button>
            </el-form-item>
        </el-form>
        <el-table
                :data="dataList"
                v-loading="loading"
                size="small"
                height="calc(100vh - 270px)"
                @selection-change="selectionChangeHandle"
                class="table">
            <el-table-column
                    type="selection"
                    width="50">
            </el-table-column>
            <el-table-column prop="patientName" label="患者姓名" show-overflow-tooltip></el-table-column>
            <el-table-column prop="cityName" label="城市" show-overflow-tooltip></el-table-column>
            <el-table-column prop="hospitalName" label="医院" show-overflow-tooltip></el-table-column>
            <el-table-column prop="departmentName" label="科室" show-overflow-tooltip></el-table-column>
            <el-table-column prop="money" label="金额(元)" show-overflow-tooltip></el-table-column>
            <el-table-column prop="payState" label="支付状态" show-overflow-tooltip>
                <template slot-scope="scope">
                    {{$dictUtils.getDictLabel("payState",scope.row.payState,'')}}
                </template>
            </el-table-column>
            <el-table-column
                    fixed="right"
                    width="250"
                    label="操作">
                <template slot-scope="scope">
                    <el-button type="text" size="mini" @click="view(scope.row)">查看</el-button>
                    <el-button type="text" size="mini" v-if="hasPermission('order:refund') && scope.row.payState == 1"
                               @click="refund(scope.row)">退款
                    </el-button>
                    <el-button type="text" size="mini" v-if="hasPermission('order:edit')" @click="edit(scope.row)">编辑
                    </el-button>
                </template>
            </el-table-column>
        </el-table>
        <el-pagination
                @size-change="sizeChangeHandle"
                @current-change="currentChangeHandle"
                :current-page="searchForm.current"
                :page-sizes="[10, 20, 50, 100]"
                :page-size="searchForm.size"
                :total="total"
                background=""
                layout="total, sizes, prev, pager, next, jumper">
        </el-pagination>
        <!--查看-->
        <OrderView ref="orderView"></OrderView>
        <!--        编辑-->
        <EditForm ref="editForm" @refreshDataList="refreshList"></EditForm>
    </div>
</template>
<script>
    import OrderView from './details'
    import EditForm from './editForm'

    export default {
        components: {OrderView, EditForm},
        data() {
            return {
                dataList: [],
                dataListSelections: [],
                loading: false,
                searchForm: {
                    current: 1,
                    size: 10,
                    economizeId: '',
                    cityId: ['', ''],
                    departmentId: '',
                    hospitalId: '',
                    patientName: '',
                    patientPhone: '',
                },
                cityOpt: [],
                hospitalOpt: [],
                departmentOpt: [],
                total: 0,
            }
        },
        activated() {
            this.refreshList()
            this.getCity()
        },
        methods: {
            // 获取数据列表
            refreshList(type) {
                if (type == 1) {
                    this.searchForm.current = 1
                }
                this.loading = true
                let parameter = JSON.parse(JSON.stringify(this.searchForm))
                parameter.economizeId = this.searchForm.cityId[0]
                parameter.cityId = this.searchForm.cityId[1]
                this.$axios(this.api.order.sysGetMingEnOrder, parameter, 'post').then(data => {
                    if (data.status) {
                        this.dataList = data.data.records
                        this.total = parseInt(data.data.total)
                        this.loading = false
                    }
                })
            },
            // 获取城市
            getCity() {
                this.$axios(this.api.order.getSysArea).then(res => {
                    if (res.status) {
                        this.cityOpt = res.data
                        this.cityOpt[0].children.map(item => {
                            if (!item.children.length) {
                                delete item.children;
                            }
                        })
                        this.getHospital()
                    }
                })
            },
            // 获取医院
            getHospital() {
                this.$axios(this.api.order.getMingenHospitalByCityId, {cityId: this.searchForm.cityId[1]}).then(res => {
                    if (res.status) {
                        this.hospitalOpt = res.data
                        this.getDepartment()
                    }
                })
            },
            // 获取科室
            getDepartment() {
                this.$axios(this.api.order.getAllMingenDepartment, {hospitalId: this.searchForm.hospitalId}).then(res => {
                    if (res.status) {
                        this.departmentOpt = res.data
                    }
                })
            },
            // 选择城市
            changeCity() {
                this.searchForm.hospitalId = ''
                this.searchForm.departmentId = ''
                this.getHospital()
            },
            // 选择医院
            changeHospital() {
                this.searchForm.departmentId = ''
                this.getDepartment()
            },
            // 重置
            resetSearch() {
                this.$refs.searchForm.resetFields()
                this.hospitalOpt = []
                this.departmentOpt = []
                this.refreshList()
            },
            // 每页数
            sizeChangeHandle(val) {
                this.searchForm.size = val
                this.searchForm.current = 1
                this.refreshList()
            },
            // 当前页
            currentChangeHandle(val) {
                this.searchForm.current = val
                this.refreshList()
            },
            // 多选
            selectionChangeHandle(val) {
                this.dataListSelections = val
            },
            // 退款
            refund(row) {
                this.$confirm(`确定退款吗?`, '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.loading = true
                    this.$axios(this.api.order.returnPayOrder, {mingenOrderId: row.id}, 'get').then(data => {
                        this.loading = false
                        if (data.status) {
                            this.$message.success('退款成功')
                            this.refreshList()
                        } else {
                            this.$message.error('退款失败')
                        }
                    })
                })
            },
            // 查看
            view(row) {
                this.$refs.orderView.init(row)
            },
            // 编辑
            edit(row) {
                this.$refs.editForm.init(row)
            },

        }
    }
</script>
